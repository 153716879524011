// initial state
const state = {
    products: []
};

// getters
const getters = {
    products: (state) => state.products
};

// mutations
const mutations = {
    CHANGE_PRODUCTS_VAL(state, val) {
        state.products = val;
    },
    ADD_ITEM(state, val){
        state.products.push(val);
    }
};

// actions
const actions = {
    addItem(context, val){
        context.commit('ADD_ITEM', val)
    },
    removeItem(context, val){
        context.state.products.splice(val, 1)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
