import Vue from 'vue'
import axios from 'axios'
import i18n from '@/plugins/i18n'

// initial state
const state = {
    profile_infos: {
        dark_mode: false,
        theme_mode: 'default',
        user_language : {
            title: 'English',
            locale: 'en',
            img:"gb.png"
        },
        widgets: [],
        table_headers: {},
        items_layout: {},
        zoom: 63
    },
    profile_id : null
};

// getters
const getters = {
    profile_infos: (state) => state.profile_infos,
    dark_mode: (state) => state.profile_infos.dark_mode,
    theme_mode: (state) => state.profile_infos.theme_mode,
    user_language: (state) => state.profile_infos.user_language,
    fixed_widgets: (state) => state.profile_infos.fixed_widgets,
    widgets: (state) => state.profile_infos.widgets,
    table_headers: state => state.profile_infos.table_headers,
    items_layout: state => state.profile_infos.items_layout,
    zoom: state => state.profile_infos.zoom,
    profile_id: (state) => state.profile_id
};

// mutations
const mutations = {
    CHANGE_PROFILE_INFOS_VAL(state, val){
        state.profile_infos = val
    },
    CHANGE_DARKMODE_VAL(state, val) {
        state.profile_infos.dark_mode = val;
        if(this.getters["profile/profile_id"] === 1)
            this.dispatch('profile/saveProfile')
    },
    CHANGE_THEMEMODE_VAL(state, val) {
        state.profile_infos.theme_mode = val;
        if(this.getters["profile/profile_id"] === 1)
            this.dispatch('profile/saveProfile')
    },
    CHANGE_USERLANGUAGE_VAL(state, val) {
        if(val) {
            axios.defaults.headers['Accept-Language'] = val.locale
            state.profile_infos.user_language = val
        }
        else {
            axios.defaults.headers['Accept-Language'] = i18n.fallbackLocale

            state.profile_infos.user_language = {
                title: 'English',
                locale: 'en',
                img:"gb.png"
            }
        }

        if(this.getters["profile/profile_id"] === 1)
            this.dispatch('profile/saveProfile')
    },
    CHANGE_WIDGETS_VAL(state, val) {
        state.profile_infos.widgets = val;
        if(this.getters["profile/profile_id"] === 1)
            this.dispatch('profile/saveProfile')
    },
    CHANGE_FIXED_WIDGETS_VAL(state, val) {
        state.profile_infos.fixed_widgets = val;
        if(this.getters["profile/profile_id"] === 1)
            this.dispatch('profile/saveProfile')
    },
    CHANGE_TABLE_HEADERS_VAL(state, val) {
        state.profile_infos.table_headers[val.entity] = val.headers
        if(this.getters["profile/profile_id"] === 1)
            this.dispatch('profile/saveProfile')
    },
    CHANGE_ITEMS_LAYOUT_VAL(state, val) {
        state.profile_infos.items_layout[val.entity] = val.val
        if(this.getters["profile/profile_id"] === 1)
            this.dispatch('profile/saveProfile', val.callback)
    },
    CHANGE_ZOOM_VAL(state, val) {
        state.profile_infos.zoom = val
        if(this.getters["profile/profile_id"] === 1)
            this.dispatch('profile/saveProfile')
    },
    CHANGE_PROFILE_ID_VAL(state,val) {
        state.profile_id = val
    }
};

// actions
const actions = {
    loadProfile(context, callback = () => {}){
        if (this.getters["auth/contact_id"] !== null && this.getters["auth/contact_id"] !== undefined){
            Vue.wsc.getList('resource/store', {}, store => {
                if (store !== null && store !== undefined && store.length !== 0 && Object.keys(store).length !== 0){
                    context.commit("CHANGE_PROFILE_ID_VAL", store.profile_id)
                    context.commit("CHANGE_PROFILE_INFOS_VAL", store.profile_infos)
                    axios.defaults.headers['Accept-Language'] = store.profile_infos.user_language.locale
                    callback()
                }
                else {
                    this.dispatch('profile/saveProfile', callback)
                }
            }, fail => {})
        }
        else {
            context.commit("CHANGE_PROFILE_INFOS_VAL", {
                dark_mode: false,
                user_language : {
                    title: 'English',
                    locale: 'en',
                    img:"gb.png"
                },
                widgets: [{name:"Default", items: []}],
                table_headers: {},
                items_layout: {}
            })

            context.commit("CHANGE_PROFILE_ID_VAL", null)
            callback()
        }
    },
    saveProfile(context, callback = () => {}){
        if (this.getters["auth/contact_id"] !== null  && this.getters["auth/contact_id"] !== undefined){
            if (context.getters.profile_id !== 1){
                Vue.wsc.createItem('resource/store', {'profile_infos' : context.getters['profile_infos'], 'profile_id': 1 }, success => {
                    context.commit("CHANGE_PROFILE_ID_VAL", 1)
                    callback('success')
                }, fail=> {
                    callback('fail')
                })
            }
            else {
                let data = {}
                data['profile_infos'] = context.getters['profile_infos']

                Vue.wsc.post('resource/store', { 'profile_infos': context.getters['profile_infos'], 'profile_id': 1 }, success => {
                    callback('success')
                }, fail=> {
                    callback('fail')
                })
            }
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
