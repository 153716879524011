import Vue from 'vue'
import Vuex from 'vuex'
import config from './modules/config'
import main from './modules/main'
import marketplace from './modules/marketplace'
import dashboard from './modules/dashboard'
import style from './modules/style'
import profile from './modules/profile'
import cart from './modules/cart'
import auth from './modules/auth'
import snackbar from './modules/snackbar'
import filebrowser from './modules/filebrowser'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        config,
        main,
        marketplace,
        dashboard,
        style,
        profile,
        cart,
        auth,
        snackbar,
        filebrowser
    }
})

export default store