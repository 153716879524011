// initial state
const state = {
    open: false,
    color: '',
    text: '',
    icon: '',
    top: true,
    bottom: false,
    timeout: 4000
};

// getters
const getters = {
    open: (state) => state.open,
    color: (state) => state.color,
    icon: (state) => state.icon,
    text: (state) => state.text,
    top: (state) => state.top,
    bottom: (state) => state.bottom,
    timeout: (state) => state.timeout
};

// mutations
const mutations = {
    CHANGE_OPEN_VAL(state, val) {
        state.open = val;
    },
    CHANGE_TEXT_VAL(state, val) {
        state.text = val;
    },
    CHANGE_COLOR_VAL(state, val){
        state.color = val;
    },
    CHANGE_ICON_VAL(state, val){
        state.icon = val;
    },
    CHANGE_TIMEOUT_VAL(state, val){
        state.timeout = val;
    }
};

// actions
const actions = {
    success(context, val){
        context.commit('CHANGE_COLOR_VAL', 'success')
        context.commit('CHANGE_ICON_VAL', 'mdi-check')
        context.commit('CHANGE_TEXT_VAL', val)
        context.commit('CHANGE_TIMEOUT_VAL', 2500)
        context.commit('CHANGE_OPEN_VAL', true)
    },
    error(context, val){
        context.commit('CHANGE_COLOR_VAL', 'accent')
        context.commit('CHANGE_ICON_VAL', 'mdi-alert')
        context.commit('CHANGE_TEXT_VAL', val)
        context.commit('CHANGE_TIMEOUT_VAL', 4000)
        context.commit('CHANGE_OPEN_VAL', true)
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
