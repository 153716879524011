export default {
	install(Vue) {
		class NVEvent {

			constructor(name) {
				this.pool = {}
				this.name = name
			}

			notify(eventName, ...args) {
				//console.log(this.name, "event ", eventName, " hook")
				if (this.pool[eventName]) {
					this.pool[eventName].forEach(callback => {
						//console.log("   ", this.name, "exec")
						callback(...args)
					});
				} else {
					//console.error("You are trying to emit un unregistered event", eventName)
				}
			}

			wait(eventName, callback) {
				//console.log(this.name, "register ", eventName)
				if (!this.pool[eventName]) {
					this.pool[eventName] = []
				}
				this.pool[eventName].push(callback)
			}
		}

		Vue.NVEvent = NVEvent;
		Vue.prototype.$NVEvent = NVEvent;
	}
};
