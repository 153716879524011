<template>
    <v-app
		    style="position: relative; height: 100%; background-color: var(--v-background-base);"
		    class="overflow-hidden"
    >
        <NavBar v-if="$route.name !== 'error'" :class="!application_ready ? 'blur': ''" :pevents="events" />

        <v-main ref="main">
            <router-view :class="!application_ready ? 'blur': ''" :pevents="events" />
        </v-main>

        <v-fab-transition>
            <v-btn
                    :class="!application_ready ? 'blur': ''"
                    @click="$vuetify.goTo(0)"
                    v-show="window_scroll_y > 350"
                    color="primary"
                    fixed
                    bottom
                    right
                    fab
                    x-small
                    class="elevation-2 mr-3"
                    style="right: 0;"
            >
                <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
        </v-fab-transition>

        <Footer v-if="$route.name !== 'home'" :class="!application_ready ? 'blur': ''" />

        <SnackBar />

        <transition name="enter-fade">
            <div style="position: absolute; width: 100%; height: 100%; z-index: 10; display: flex; flex-direction: column; justify-content: center; align-items: center; overflow: hidden" v-if="!application_ready">
                <div id='particles-js'></div>
                <div class="text-center _inner" style="pointer-events: none">
                    <v-img v-if="$store.getters['auth/selected_site'] !== null && $store.getters['auth/selected_site'].logo !== null" :src="$store.getters['config/backend_url'] + $store.getters['auth/selected_site'].logo" style="width: 300px; height: 75px" class="mb-12" />
                    <img class="pulse" :src="require('@/assets/icon_color.svg')" style="height: 70px; width: 70px;" />
                </div>
            </div>
        </transition>
    </v-app>
</template>

<script>

    export default {
        name: 'App',
        components: {
            NavBar: () => import('@/layout/NavBar.vue'),
            Footer: () => import('@/layout/Footer.vue'),
            SnackBar: () => import('@/components/ui/SnackBar.vue')
        },
        data(){
            return {
                events: null
            }
        },
        computed: {
            window_scroll_y() {
                return this.$store.getters.window_scroll_y;
            },
            application_ready(){
                return this.$store.getters['application_ready']
            },
            fontSize() {
                return this.$store.getters['profile/zoom']
            }
        },
        watch: {
            application_ready(val){
                if (val){
                    setTimeout(() => {
                        window.pJSDom[0].pJS.fn.vendors.destroypJS()
                    }, 1000)
                }
                else {
                    require('particles.js')

                    this.$nextTick(() => {
                        this.initParticlesJS()
                    })
                }
            },
            '$route'(newRoute, oldRoute){
                document.title = (this.$store.getters['auth/selected_site'] !== null ? this.$options.filters.firstCapital(this.$store.getters['auth/selected_site'].display_name) : 'Nuvea PaaS') + ' - ' + (newRoute.meta.route_label ? newRoute.meta.route_label : this.$options.filters.firstCapital(newRoute.name))

                if(newRoute.name !== 'login' && newRoute.name !== 'register' && newRoute.fullPath !== oldRoute.fullPath) {
                    this.$store.commit('CHANGE_REDIRECT_VAL', this.$route.fullPath)
                }
            },
            '$vuetify.breakpoint.width'(val){
                if(val < 960) {
                    this.$store.commit("dashboard/CHANGE_DRAWER_MINI_VAL", false);
                }
                else {
                    this.$store.commit("dashboard/CHANGE_DRAWER_VAL", true);
                }
            }
        },
        methods: {
            initParticlesJS() {
                particlesJS('particles-js', {
                    'particles': {
                        'number': {
                            'value': 80,
                            'density': {
                                'enable': true,
                                'value_area': 800
                            }
                        },
                        'color': {
                            'value': '#0072bc'
                        },
                        'shape': {
                            'type': 'circle',
                            'stroke': {
                                'width': 0,
                                'color': '#000000'
                            },
                            'polygon': {
                                'nb_sides': 5
                            },
                            'image': {
                                'src': 'img/github.svg',
                                'width': 100,
                                'height': 100
                            }
                        },
                        'opacity': {
                            'value': 0.5,
                            'random': true,
                            'anim': {
                                'enable': false,
                                'speed': 1,
                                'opacity_min': 0.1,
                                'sync': false
                            }
                        },
                        'size': {
                            'value': 10,
                            'random': true,
                            'anim': {
                                'enable': false,
                                'speed': 80,
                                'size_min': 0.1,
                                'sync': false
                            }
                        },
                        'line_linked': {
                            'enable': true,
                            'distance': 200,
                            'color': '#0072bc',
                            'opacity': 0.3,
                            'width': 2
                        },
                        'move': {
                            'enable': true,
                            'speed': 12,
                            'direction': 'none',
                            'random': false,
                            'straight': false,
                            'out_mode': 'out',
                            'bounce': false,
                            'attract': {
                                'enable': false,
                                'rotateX': 600,
                                'rotateY': 1200
                            }
                        }
                    },
                    'interactivity': {
                        'detect_on': 'canvas',
                        'events': {
                            'onhover': {
                                'enable': true,
                                'mode': 'repulse'
                            },
                            'onclick': {
                                'enable': false,
                                'mode': 'push'
                            },
                            'resize': true
                        },
                        'modes': {
                            'grab': {
                                'distance': 800,
                                'line_linked': {
                                    'opacity': 1
                                }
                            },
                            'bubble': {
                                'distance': 800,
                                'size': 80,
                                'duration': 2,
                                'opacity': 0.8,
                                'speed': 3
                            },
                            'repulse': {
                                'distance': 200,
                                'duration': 0.4
                            },
                            'push': {
                                'particles_nb': 4
                            },
                            'remove': {
                                'particles_nb': 2
                            }
                        }
                    },
                    'retina_detect': true
                })
            },
            getWindowScroll() {
                this.$store.commit("CHANGE_WINDOW_SCROLL_Y_VAL", window.scrollY);
            },
            setUpHooks(){
                this.events = new this.$NVEvent('App')
            }
        },
        created(){
            this.setUpHooks()

            require('particles.js')

            this.$nextTick(() => {
                this.initParticlesJS()
            })

            this.$store.dispatch('config/loadConfig')

            this.$vuetify.theme.dark = this.$store.getters['profile/dark_mode']
        },
        mounted(){
            window.addEventListener("scroll", this.getWindowScroll);
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.getWindowScroll);
        }
    };

</script>

<style>
    html {
        font-size: 62.5% !important;
    }

    body * {
        font-family: "Montserrat", sans-serif !important;
        letter-spacing: 0.4px;
    }

    #particles-js {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .blur {
        -webkit-filter: blur(26px);
        -moz-filter: blur(26px);
        -o-filter: blur(26px);
        -ms-filter: blur(26px);
        filter: blur(26px);
    }

    .enter-fade-enter-active, .enter-fade-leave-active  {
        transition: all ease-out 1s;
    }

    .enter-fade-enter, .enter-fade-leave-to {
        opacity: 0;
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
    }

    .enter-fade-enter-active ._inner, .enter-fade-leave-active ._inner  {
        transition: all ease-out 1s;
        transform: scale(1);
    }

    .enter-fade-enter ._inner, .enter-fade-leave-to ._inner {
        opacity: 0;
        transform: scale(1.1);
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
    }

    *{
        word-break: break-word;
        scrollbar-color: var(--v-secondary-base) var(--v-background-base) !important;
        scrollbar-width: thin;
    }

    .scrollbar-not-secondary {
        scrollbar-color: var(--v-darkbackground-base) transparent !important;

    }

    *::-webkit-scrollbar {
        width: 8px;
    }

    *::-webkit-scrollbar-track {
        background-color: var(--v-background-base);
    }

    *::-webkit-scrollbar-thumb {
        background-color: var(--v-secondary-base);
        border-radius: 5px;
    }

    .h1 {
        font-size: 8rem;
        font-weight: 500;
    }

    .h2 {
        font-size: 4.8rem;
        font-weight: 500;
    }

    .h3 {
        font-size: 4rem;
        font-weight: 500;
    }

    .h4 {
        font-size: 3.2rem;
        font-weight: 500;
    }

    .h5 {
        font-size: 2.6rem;
        font-weight: 500;
    }

    .headline-1 {
        font-size: 2.4rem;
        font-weight: 500;
    }

    .title-1 {
        font-size: 2.2rem;
        font-weight: 500;
    }

    .title-2 {
        font-size: 2rem;
        font-weight: 500;
    }

    .title-3 {
        font-size: 1.8rem;
        font-weight: 500;
    }

    .sub-title-1 {
        font-size: 1.6rem;
        font-weight: 500;
    }

    .sub-title-2 {
        font-size: 1.4rem;
        font-weight: 500;
    }

    .paragraph, .paragraph input, .paragraph textarea {
        font-size: 1.3rem;
        font-weight: 500;
    }

    .caption-1 {
        font-size: 1.13rem;
        font-weight: 500;
    }

    .caption-2 {
        font-size: 0.95rem;
        font-weight: 500;
    }

    ::placeholder {
        font-size: 1.3rem;
        color: var(--v-label-base) !important;
    }

    .v-list-item__title {
        font-size: 1.3rem !important;
    }

    .v-application p {
        margin-bottom: 0 !important;
    }

    .actions .v-skeleton-loader__button {
        min-width: 123px;
        max-width: 123px;
    }
    .actions .cell .v-skeleton-loader__button  {
        min-width: 123px;
        max-width: 223px;
    }

    .router-link {
        text-decoration: none;
        font-weight: inherit;
    }

    .noselect {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    .cursor-pointer {
        cursor: pointer !important;
    }

    .transition-cubic {
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
    }

    .v-card__title {
        word-break: normal !important;
    }

    .v-expansion-panel-content__wrap {
        padding: 0 !important;
    }

    .transition-rotate {
        transform: rotate(-180deg);
    }
    .transition-90-rotate {
        transform: rotate(-90deg);
    }

    .vdatetime {
        display: flex !important;
        align-items: center !important;
    }
    .vdatetime .vdatetime-calendar__month__day {
        font-weight: 500;
    }
    .vdatetime .vdatetime-popup {
        color: var(--v-label-base);
        background: var(--v-cell-base);
    }
    .vdatetime .vdatetime-input {
        width: 100%;
        padding: 5px 12px;
        font-size: 1.3rem;
        background-color: var(--v-cell-base);
    }
    .vdatetime .vdatetime-input::placeholder {
        font-size: 1.3rem;
    }
    .vdatetime-dark .vdatetime-input{
        border-bottom: 1px solid var(--v-label-base);
        color: white;
    }
    .vdatetime-light .vdatetime-input{
        border-bottom: 1px solid var(--v-label-base);
    }
    .vdatetime-dark.outlined .vdatetime-input{
        border: 1px solid var(--v-label-darken3);
        border-radius: 8px;
        color: white;
    }
    .vdatetime-dark.outlined.focus .vdatetime-input{
        border: 1px solid var(--v-primary-base);
        border-radius: 8px;
        color: white;
    }
    .vdatetime-light.outlined .vdatetime-input{
        border: 1px solid var(--v-label-lighten2);
        border-radius: 8px;
    }
    .vdatetime-popup {
        -webkit-transform: none !important;
        transform: none !important;
        left: calc(50% - 170px) !important;
        top: calc(50% - 236px) !important;
        border-radius: 8px !important;
        overflow: hidden;
        font-family: "Cabin", sans-serif !important;
    }
    .vdatetime-popup__year {
        font-weight: bold !important;
    }
    .vdatetime-popup__header, .vdatetime-calendar__month__day--selected > span > span, .vdatetime-calendar__month__day--selected:hover > span > span {
        background-color: var(--v-secondary-base) !important;
    }
    .vdatetime .vdatetime-calendar__month__day:hover > span > span {
        background: var(--v-cell3-base);
    }
    .vdatetime-popup__actions__button, .vdatetime-time-picker__item--selected {
        color: var(--v-secondary-base) !important;
    }
    .vdatetime-time-picker__list::-webkit-scrollbar {
        width: auto !important;
    }

    .v-dialog:not(.v-dialog--fullscreen) {
        border-radius: 8px !important
    }

    .slide-fade-enter-active {
        position: absolute;
        width: calc(100% - 24px);
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        position: absolute;
        width: calc(100% - 24px);
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to {
        position: absolute;
        width: calc(100% - 24px);
        transform: translateX(-100%);
        opacity: 0;
    }

    .slide-x-fade-enter-active {
        position: absolute;
        z-index: 1;
        width: calc(100% - 24px);
        transition: all .3s ease;
    }
    .slide-x-fade-leave-active {
        position: absolute;
        width: calc(100% - 24px);
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-x-fade-enter {
        position: absolute;
        width: calc(100% - 24px);
        transform: translateX(100%);
        opacity: 0;
    }
    .slide-x-fade-leave-to {
        position: absolute;
        width: calc(100% - 24px);
        transform: translateX(-100%);
        opacity: 0;
    }

    .slide-x-fade-reverse-enter-active {
        position: absolute;
        width: calc(100% - 24px);
        transition: all .3s ease;
    }
    .slide-x-fade-reverse-leave-active {
        position: absolute;
        width: calc(100% - 24px);
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-x-fade-reverse-enter {
        position: absolute;
        width: calc(100% - 24px);
        transform: translateX(-100%);
        opacity: 0;
    }
    .slide-x-fade-reverse-leave-to {
        position: absolute;
        width: calc(100% - 24px);
        transform: translateX(100%);
        opacity: 0;
    }

    .slide-y-fade-enter-active {
        position: absolute;
        width: calc(100% - 24px);
        transition: all .3s ease;
    }
    .slide-y-fade-leave-active {
        position: absolute;
        width: calc(100% - 24px);
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-y-fade-enter, .slide-y-fade-leave-to {
        position: absolute;
        width: calc(100% - 24px);
        transform: translateX(100%);
        opacity: 0;
    }

    .slide-enter {
        transform: translateY(200px);
    }

    .slide-enter-active {
        transition: transform 0.3s ease-out;
    }

    .slide-enter-to {
        transform: translateY(0px);
    }

    .slide-leave {
        transform: translateX(0px);
    }

    .slide-leave-active {
        transition: transform 0.3s ease-out;
        position: absolute;
    }

    .slide-leave-to {
        transform: translateX(-1000px);
    }

    .slide-move {
        transition: transform 0.7s;
    }

    .v-toolbar__content {
        padding: 0 0 0 16px !important;
    }

    .v-toolbar__extension {
        padding : 0 !important;
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) !important;
    }

    .v-text-field--filled.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled > .v-input__control > .v-input__slot {
        min-height: 30px !important;
    }

    .v-list {
        padding: 0 !important;
    }

    .pulse {
        border-radius: 50%;
        animation: animate 1s linear infinite;
    }
    .v-expansion-panel-header {
        min-height: 25px !important;
    }


    .v-calendar-daily .v-event {
	    display: none;
    }
    .v-event-more {
        background-color:var(--v-cell3-base) !important;
        border-radius:0.5em;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
        text-align: center;
    }

    div.tooltip {
        position: absolute;
        text-align: center;
        padding: 8px;
        border-radius: 8px;
        pointer-events: none;
        background-color: var(--v-cell-base);
        color: var(--v-black2white-base);
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)
    }

    @keyframes animate {
        0% {
            box-shadow: 0 0 0 0 rgba(0, 114, 188, 0.8), 0 0 0 0 rgba(0, 114, 188, 0.8), 0 0 0 0 rgba(0, 114, 188, 0.8);
            transform: scale(1);
        }
        10% {
            transform: scale(0.85);
        }
        25%{
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(0, 114, 188, 0.2), 0 0 0 3px rgba(0, 114, 188, 0.25), 0 0 0 0 rgba(0, 114, 188, 0);
        }
        50%{
            transform: scale(1);
            box-shadow: 0 0 0 20px rgba(0, 114, 188, 0.1), 0 0 0 13px rgba(0, 114, 188, 0.15), 0 0 0 6px rgba(0, 114, 188, 0.25);
        }
        75%{
            transform: scale(0.8);
            box-shadow: 0 0 0 30px rgba(0, 114, 188, 0), 0 0 0 23px rgba(0, 114, 188, 0.05), 0 0 0 16px rgba(0, 114, 188, 0.10);
        }
        100% {
            transform: scale(1);
            box-shadow: 0 0 0 30px rgba(0, 114, 188, 0), 0 0 0 30px rgba(0, 114, 188, 0), 0 0 0 23px rgba(0, 114, 188, 0);
        }
    }
</style>
