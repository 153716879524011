// initial state
const state = {
    drawer: true,
    drawer_ads: false,
    catalog: {
        items_per_page: 16,
        page : 1,
    },
    ads: []
};

// getters
const getters = {
    drawer: (state) => state.drawer,
    drawer_ads: (state) => state.drawer_ads,
    catalog: (state) => state.catalog,
    ads: (state) => state.ads
};

// mutations
const mutations = {
    CHANGE_DRAWER_VAL(state, val) {
        state.drawer = val;
    },
    CHANGE_DRAWER_ADS_VAL(state, val) {
        state.drawer_ads = val;
    },
    CHANGE_ITEMS_PER_PAGE_VAL(state, val) {
        state.catalog.items_per_page = val;
    },
    CHANGE_PAGE_VAL(state, val) {
        state.catalog.page = parseInt(val);
    },
    CHANGE_ADS_VAL(state, val) {
        state.ads = val;
    }
};

// actions
const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
