import router from '@/router/index'
import vue from 'vue'

const state = {
    account_id: null,
    contact_id: null,
    available_accounts: [],
    selected_account: null,
    contact_info: null,
    selected_site: null,
    available_sites: null,
    sites: null,
}

// getters
const getters = {
    account_id: state => state.account_id,
    contact_id: state => state.contact_id,
    available_accounts: state => state.available_accounts,
    selected_account: state => state.selected_account,
    contact_info: state => state.contact_info,
    selected_site: state => state.selected_site,
    available_sites: state => state.available_sites,
    sites: state => state.sites
}

// mutations
const mutations = {
    CHANGE_ACCOUNT_ID_VAL (state, val) {
        state.account_id = val
    },
    CHANGE_CONTACT_ID_VAL (state, val) {
        state.contact_id = val
    },
    CHANGE_AVAILABLE_ACCOUNTS_VAL (state, val) {
        state.available_accounts = val
    },
    CHANGE_SELECTED_ACCOUNT_VAL (state, val) {
        state.selected_account = val
    },
    CHANGE_CONTACT_INFO_VAL (state, val) {
        state.contact_info = val
    },
    CHANGE_SELECTED_SITE_VAL (state, val) {
        state.selected_site = val
    },
    CHANGE_AVAILABLE_SITES_VAL (state, val) {
        state.available_sites = val
    },
    CHANGE_SITES_VAL (state, val) {
        state.sites = val
    }
}

// actions
const actions = {
    logOut () {
        vue.wsc.post('user/logout', {}, () => {
            this.commit('cart/CHANGE_PRODUCTS_VAL', [])
            this.dispatch('auth/loginState')
        }, () => {
            this.dispatch('auth/loginState')
        })
    },
    loginState (context) {
        vue.wsc.changeUrl(this.getters['config/backend_url'])

        vue.wsc.getList('site', {}, sites => {
            context.commit('CHANGE_SITES_VAL', sites)

            vue.wsc.getList('user/info', {}, (response) => {
                context.commit('CHANGE_ACCOUNT_ID_VAL', response.contact.selected_account.id)
                context.commit('CHANGE_CONTACT_ID_VAL', response.contact.id)
                context.commit('CHANGE_AVAILABLE_ACCOUNTS_VAL', response.contact.account)
                context.commit('CHANGE_SELECTED_ACCOUNT_VAL', response.contact.selected_account)

                let res = []

                response.contact.site.forEach(e => {
                    let site = sites.find(f => f.id === e.id)

                    if(site)
                        res.push(site)
                })

                context.commit('CHANGE_AVAILABLE_SITES_VAL', res)
                context.commit('CHANGE_CONTACT_INFO_VAL', response.contact)
                context.commit('CHANGE_SELECTED_SITE_VAL', sites.find(e => e.current))

                this.dispatch('profile/loadProfile', () => {
                    this.dispatch('loadRoutes', this.getters['profile/user_language'].locale)
                })
            }, () => {
                context.commit('CHANGE_ACCOUNT_ID_VAL', null)
                context.commit('CHANGE_CONTACT_ID_VAL', null)
                context.commit('CHANGE_AVAILABLE_ACCOUNTS_VAL', [])
                context.commit('CHANGE_SELECTED_ACCOUNT_VAL', null)
                context.commit('CHANGE_AVAILABLE_SITES_VAL', null)
                context.commit('CHANGE_SELECTED_SITE_VAL', null)
                context.commit('CHANGE_CONTACT_INFO_VAL', null)

                this.dispatch('loadRoutes', this.getters['profile/user_language'].locale)
            }, false)
        }, fail => {

        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
