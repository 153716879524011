// initial state
const state = {
	backend_url: ''
};

// getters
const getters = {
	backend_url: (state) => state.backend_url
};

// mutations
const mutations = {
	CHANGE_BACKEND_URL_VAL(state, val) {
		state.backend_url = val;
	}
};

// actions

const actions = {
	loadConfig(context, val){
		fetch("/config.json")
			.then((res) => res.json())
			.then((data) => {
				context.commit('CHANGE_BACKEND_URL_VAL', data.backend_url)
				this.dispatch('auth/loginState')
			})
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
