// initial state
const state = {
	classes: {
		container: "rounded-lg cell elevation-2 pa-4"
	}
};

// getters
const getters = {
	classes: (state) => state.classes
};

// mutations
const mutations = {
};

// actions
const actions = {
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
