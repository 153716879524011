// initial state
const state = {
	elements: [
		{
			id: 1,
			name: "Shrek",
			elements: [],
			icon: "mdi-folder"
		},
		{
			id: 2,
			name: "Fiona",
			elements: [
				{
					id: 4,
					name: "Lord Farquad",
					elements: []
				},
				{
					id: 5,
					name: "Prince Charming",
					elements: []
				}
			]
		},
		{
			id: 3,
			name: "Donkey",
			elements: [],
			icon: "mdi-folder"
		}
	]
};

// getters
const getters = {
	elements: (state) => state.elements
};

// mutations
const mutations = {
	updateElements: (state, payload) => {
		state.elements = payload;
	}
};

// actions
const actions = {
	updateElements: ({ commit }, payload) => {
		commit("updateElements", payload);
	}
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
