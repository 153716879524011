import vue from 'vue'
import colors from "vuetify/lib/util/colors";

export default {
    install(Vue) {
        class Tools {
            constructor() {
                this.themes = {
                    default: {
                        style: {
                            filter: ''
                        },
                        light: {
                            primary: '#0072bc',
                            secondary: '#26a69a',
                            accent: colors.red.darken1,
                            cell: colors.shades.white,
                            cell2: colors.grey.lighten3,
                            cell3: colors.grey.lighten2,
                            cell4: colors.grey.base,
                            cell5: colors.shades.white,
                            celldark: colors.grey.darken2,
                            background: colors.grey.lighten4,
                            label: colors.grey.darken2,
                            input: '#9e9e9e',
                            inputHover: '#3e3e3e',
                            black2white: colors.shades.black,
                            sidebar: '#111111',
                            labelprimary: '#0082d3',
                            success: '#0f9d58',
                            error: '#db4437',
                            info: '#4285f4',
                            warning: colors.orange.darken1
                        },
                        dark: {
                            primary: '#0082d3',
                            secondary: '#26a69a',
                            accent: colors.red.lighten1,
                            cell: "#252525",
                            cell2: '#353535',
                            cell3: colors.grey.darken3,
                            cell4: colors.grey.darken1,
                            cell5: '#353535',
                            celldark: colors.grey.darken2,
                            background: "#111111",
                            label: colors.grey.base,
                            input: '#595959',
                            inputHover: '#a5a5a5',
                            black2white: colors.shades.white,
                            sidebar: '#000000',
                            labelprimary: '#4fbbff',
                            success: '#0f9d58',
                            error: '#db4437',
                            info: '#4285f4',
                            warning: colors.orange.darken1
                        },
                    },
                    djangoGirls : {
                        style: {
                            filter: 'hue-rotate(90deg)'
                        },
                        light: {
                            primary: '#c965cf',
                            secondary: '#56109c',
                            accent: '#8a0718',
                            cell: colors.shades.white,
                            cell2: colors.grey.lighten3,
                            cell3: colors.grey.lighten2,
                            cell4: colors.grey.base,
                            cell5: colors.shades.white,
                            celldark: colors.grey.darken2,
                            background: colors.grey.lighten4,
                            label: colors.grey.darken2,
                            input: '#9e9e9e',
                            inputHover: '#3e3e3e',
                            black2white: colors.shades.black,
                            sidebar: '#111111',
                            labelprimary: '#c965cf',
                            success: '#0f9d58',
                            error: '#8a1d1d',
                            info: '#4285f4',
                            warning: colors.orange.darken1
                        },
                        dark: {
                            primary: '#c965cf',
                            secondary: '#56109c',
                            accent: '#8a0718',
                            cell: "#252525",
                            cell2: '#353535',
                            cell3: colors.grey.darken3,
                            cell4: colors.grey.darken1,
                            cell5: '#353535',
                            celldark: colors.grey.darken2,
                            background: "#111111",
                            label: colors.grey.base,
                            input: '#595959',
                            inputHover: '#a5a5a5',
                            black2white: colors.shades.white,
                            sidebar: '#000000',
                            labelprimary: '#c965cf',
                            success: '#0f9d58',
                            error: '#8a1d1d',
                            info: '#4285f4',
                            warning: colors.orange.darken1
                        }
                    },
                    eighties : {
                        style: {
                            filter: 'hue-rotate(22deg) brightness(49%) saturate(285%) contrast(145%) invert(100%)'
                        },
                        light: {
                            primary: '#dbb302',
                            secondary: '#0400ff',
                            accent: colors.red.darken1,
                            cell: colors.shades.white,
                            cell2: colors.grey.lighten3,
                            cell3: colors.grey.lighten2,
                            cell4: colors.grey.base,
                            cell5: colors.shades.white,
                            celldark: colors.grey.darken2,
                            background: colors.grey.lighten5,
                            label: colors.grey.darken2,
                            input: '#9e9e9e',
                            inputHover: '#3e3e3e',
                            black2white: colors.shades.black,
                            sidebar: '#111111',
                            labelprimary: '#ffee00',
                            success: '#0f9d58',
                            error: '#db4437',
                            info: '#4285f4',
                            warning: colors.orange.darken1
                        },
                        dark: {
                            primary: '#dbb302',
                            secondary: '#0400ff',
                            accent: colors.red.lighten1,
                            cell: '#00f',
                            cell2: '#00c',
                            cell3: '#009',
                            cell4: '#006',
                            cell5: '#00f',
                            celldark: colors.grey.darken2,
                            background: '#0f0',
                            label: colors.grey.base,
                            input: '#595959',
                            inputHover: '#a5a5a5',
                            black2white: colors.shades.white,
                            sidebar: '#000000',
                            labelprimary: '#ffee00',
                            success: '#0f9d58',
                            error: '#db4437',
                            info: '#4285f4',
                            warning: colors.orange.darken1
                        }
                    },

                }
            }

            pSBC(p, c0, c1, l) {
                let r, g, b, P, f, t, h, i = parseInt, m = Math.round, a = typeof (c1) == "string";
                if (typeof (p) != "number" || p < -1 || p > 1 || typeof (c0) != "string" || (c0[0] != 'r' && c0[0] != '#') || (c1 && !a)) return null;
                let pSBCr;
                if (!pSBCr) pSBCr = (d) => {
                    let n = d.length, x = {};
                    if (n > 9) {
                        [r, g, b, a] = d = d.split(","), n = d.length;
                        if (n < 3 || n > 4) return null;
                        x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4)), x.g = i(g), x.b = i(b), x.a = a ? parseFloat(a) : -1
                    } else {
                        if (n == 8 || n == 6 || n < 4) return null;
                        if (n < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
                        d = i(d.slice(1), 16);
                        if (n == 9 || n == 5) x.r = d >> 24 & 255, x.g = d >> 16 & 255, x.b = d >> 8 & 255, x.a = m((d & 255) / 0.255) / 1000;
                        else x.r = d >> 16, x.g = d >> 8 & 255, x.b = d & 255, x.a = -1
                    }
                    return x
                };
                h = c0.length > 9, h = a ? c1.length > 9 ? true : c1 == "c" ? !h : false : h, f = pSBCr(c0), P = p < 0, t = c1 && c1 != "c" ? pSBCr(c1) : P ? {
                    r: 0,
                    g: 0,
                    b: 0,
                    a: -1
                } : {r: 255, g: 255, b: 255, a: -1}, p = P ? p * -1 : p, P = 1 - p;
                if (!f || !t) return null;
                if (l) r = m(P * f.r + p * t.r), g = m(P * f.g + p * t.g), b = m(P * f.b + p * t.b);
                else r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5), g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5), b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
                a = f.a, t = t.a, f = a >= 0 || t >= 0, a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0;
                if (h) return "rgb" + (f ? "a(" : "(") + r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "") + ")";
                else return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2)
            }

            log(/**/){
                var args = arguments;

                for(var i = 0; i < args.length; i++){
                    console.log(args[i]);
                }
            }

            filterObject(obj, fn){
                let ret  = {}

                Object.keys(obj).forEach((e)=>{
                    if (fn(obj[e])){
                        ret[e] = obj[e]
                    }
                })

                return ret
            }

            sortObject(obj, field){
                let resObj = {}
                let resArray = []
                let resKeys = Object.keys(obj)

                resKeys.forEach(e => {
                    let o = {}
                    o['_key'] = e
                    o[field] = obj[e][field]

                    resArray.push(o)
                })

                resArray = resArray.sort((a, b) => {
                    if(a[field].toLowerCase() <= b[field].toLowerCase()){
                        return -1
                    }
                    else {
                        return 1
                    }
                })

                resArray.forEach(e => {
                    resObj[e['_key']] = obj[e['_key']]
                })

                return resObj
            }

            transparentize(val, opacity, $vuetify) {
                let color = $vuetify.theme.dark ? $vuetify.theme.themes.dark[val] : $vuetify.theme.themes.light[val]

                if(typeof color === 'undefined'){
                    if(val.split(' ').length > 1){
                        let root = val.split(' ')[0]

                        if (root.split('-').length > 1) {
                            let first = root.split('-')[0]
                            let second = root.split('-')[1]

                            root = first + String.fromCharCode(second.charCodeAt(0) - 32) + second.slice(1, second.length)
                        }

                        let shade = val.split(' ')[1]
                        shade = shade.replace('-', '')

                        if(typeof colors[root] !== 'undefined'){
                            if(typeof colors[root][shade] !== 'undefined') {
                                color = colors[root][shade]
                            }
                            else {
                                //console.log('this shade is not defined:', shade)
                                color = '#000'
                            }
                        }
                        else {
                            //console.log('this color is not defined:', val.split(' ')[0])
                            color = '#000'
                        }
                    }
                    else if (val.split('-').length > 1) {
                        let first = val.split('-')[0]
                        let second = val.split('-')[1]

                        val = first + String.fromCharCode(second.charCodeAt(0) - 32) + second.slice(1, second.length)

                        if(typeof colors[val] !== 'undefined'){
                            color = colors[val].base
                        }
                        else {
                            //console.log('this color is not defined:', val)
                            color = '#000'
                        }
                    }
                    else {
                        if(typeof colors[val] !== 'undefined'){
                            color = colors[val].base
                        }
                        else {
                            if(typeof colors.shades[val] !== 'undefined'){
                                color = colors.shades[val]
                            }
                            else {
                                //console.log('this color is not defined:', val)
                                color = '#000'
                            }
                        }
                    }
                }

                if(color[0] === '#'){
                    if(color.length === 4){
                        let r = Number.parseInt(color[1], 16)
                        let g = Number.parseInt(color[2], 16)
                        let b = Number.parseInt(color[3], 16)

                        return 'rgba('+ r +','+ g +','+ b +','+ opacity +')'
                    }
                    else if(color.length === 7){
                        let r = Number.parseInt(color[1] + color[2], 16)
                        let g = Number.parseInt(color[3] + color[4], 16)
                        let b = Number.parseInt(color[5] + color[6], 16)

                        return 'rgba('+ r +','+ g +','+ b +','+ opacity +')'
                    }
                }
            }
        }

        let tools = new Tools();
        Vue.tools = tools;
        Vue.prototype.$tools = tools;
    }
};
