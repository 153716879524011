import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import wsc from './plugins/api'
import tools from './plugins/tools'
import VueDraggable from 'vuedraggable'
import i18n from './plugins/i18n'
import moment from './plugins/moment'
import NVEvent from "./plugins/nvevent"

Vue.config.productionTip = false

Vue.use(VueDraggable)
Vue.use(wsc)
Vue.use(tools)
Vue.use(moment)
Vue.use(NVEvent)

new Vue({
	router,
	vuetify,
	store,
	wsc,
	tools,
	i18n,
	moment,
	NVEvent,
	render: h => h(App)
}).$mount('#app')


Vue.filter('firstCapital', function (val) {
	if(typeof val === 'undefined' || val === null){
		return ''
	}

	let lower = val.toString().toLowerCase()

	if(lower.charCodeAt(0) >= 97 && lower.charCodeAt(0) < 123)
		return String.fromCharCode(lower.charCodeAt(0) - 32) + val.slice(1, val.length)
	else
		return val
})

Vue.filter('toLocalDate', function (val) {
	if (val && val.match('Z') !== null) {

		return Vue.moment(new Date(val)).format('YYYY-MM-DD HH:mm')
	} else {
		return val
	}
})

Vue.filter('toUTCDate', function (val) {
	if (val && val.match('Z') === null) {

		return new Date(val).toISOString()
	} else {
		return val

	}
})

Vue.filter('striphtml', function (value) {
	var div = document.createElement("div");
	div.innerHTML = value;
	var text = div.textContent || div.innerText || "";

	return text;
})

Vue.filter('formatPrice', function (val, currency) {
	if (val) {
		let integer = val.toString().split('.')[0]
		let decimal = '00'

		if (val.toString().split('.')[1] !== undefined) {
			decimal = val.toString().split('.')[1]
		}

		integer = integer.slice(-integer.length, -3) + ' ' + integer.slice(-3)

		return integer + currency + '<sup>' + decimal[0] + decimal[1] + '</sup>'
	}

	return ''
})
