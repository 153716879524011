import Vue from 'vue'
import VueRouter from 'vue-router'

class VueRouterEx extends VueRouter {
	constructor(options) {
		super(options);
		this.errors = false
		this.addRoutes = (newRoutes) => {
			this.options.routes.push(...newRoutes)
			this.matcher.addRoutes(newRoutes)
		};
		this.resetRoutes = () => {
			this.options.routes = [...routes]
			this.matcher = new VueRouterEx(options).matcher
		};
		this.replaceRoutes = (newRoutes, callback) => {
			this.resetRoutes()
			this.addRoutes(newRoutes)
			callback()
		};
		this.ready = () => {
			this.errors = true
		}
	}
}

const routes = [
	{
		path: '/auth',
		name: 'auth',
		redirect: '/auth/login',
		component: () => import('@/layout/Auth.vue'),
		children: [
			{
				path: 'login',
				name: 'login',
				component: () => import('@/components/auth/Login.vue')
			},
			{
				path: 'register',
				name: 'register',
				component: () => import('@/components/auth/Register.vue')
			}
		]
	}
]

Vue.use(VueRouterEx);

const router = new VueRouterEx({
	mode: "history",
	routes: [...routes],
	linkActiveClass: "active",
});

export default router
