import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
    const Locales = require.context('@/locales', true, /[A-Za-z0-9-_]+$/i)
    const messages = {}

    Locales.keys().forEach(key => {
        const split = key.split('/')
        const lng = split[1]

        if (messages[lng] === undefined) { messages[lng] = {} }

        if (key.startsWith('./' + lng + '/') && key.match(/[A-Za-z0-9-_]+\.json/i)) {
            const file = new Locales(key)
            const category = split[split.length - 1].replace('.json', '')

            if (messages[lng][category] === undefined) { messages[lng][category] = {} }

            messages[lng][category] = Object.assign(messages[lng][category], file)
        }
    })

    return messages
}

export default new VueI18n({
    locale: window.navigator.language.slice(0, 2),
    fallbackLocale: 'en',
    messages: loadLocaleMessages()
})
