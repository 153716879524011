import axios from "axios";
import store from '@/store/index'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.withCredentials = true
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
export default {
    install(Vue) {
        class wsc {
            constructor() {
                this.api_url = ''
            }

            changeUrl(url) {
                this.api_url = url
            }

            static downloadOrCallback(response, callback) {
                let ret = response.headers['content-disposition'];
                if (ret) {
                    if (ret.match(new RegExp("attachment"))) {
                        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        let fileLink = document.createElement('a');
                        let file_name = ret.split('=')[1].replace(/"/g, "")

                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', file_name);
                        document.body.appendChild(fileLink);
                        fileLink.click();

                        callback(response.data)
                    } else {
                        callback(response.data)
                    }
                } else {
                    callback(response.data)
                }
            }

            static formDataHeaders() {
                return {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                };
            }

            toFormData(data, callback) {
                let formData = new FormData();
                let count = 0;
                for (let key in data) {
                    formData.append(key, data[key]);
                    count++;

                    if (count >= Object.keys(data).length) {
                        callback(formData, wsc.formDataHeaders());
                    }
                }
            }

            toParamData(data, callback) {
                let count = 0;
                let params = []
                if (Object.keys(data).length !== 0 || data.constructor !== Object) {
                    for (let key in data) {
                        if (Array.isArray(data[key])) {
                            data[key].forEach((e) => {
                                params.push(key + "=" + e)
                            })
                        }
                        else {
                            params.push(key + "=" + data[key])
                        }

                        count++;

                        if (count >= Object.keys(data).length) {
                            callback(params.join("&"))
                        }
                    }
                } else {
                    callback(params.join("&"))
                }
            }

            getFields(fields, params, parentItem, callback) {
                let ready = Object.keys(fields).length

                if (ready === 0) {
                    callback(fields)
                    return
                }

                for (let key in fields) {
                    if (fields[key].type === 'select' || fields[key].type === 'multiselect') {
                        this.getField(params, parentItem, fields[key], (choices) => {

                            fields[key].choices = choices
                            ready--

                            if (ready === 0) {
                                callback(fields)
                            }
                        })
                    }
                    else {
                        ready--

                        if (ready === 0) {
                            callback(fields)
                        }
                    }
                }
            }

            getField(params, parentItem, field, callback) {
                let res = []

                for (let token of field.resource.endpoint.split('/')) {
                    if (token.match(':')) {
                        let item_field = token.replace(':', '')

                        if (typeof params[item_field] !== 'undefined') {
                            res.push(params[item_field])
                        }
                        else {
                            let item_id = parentItem[item_field].id
                            res.push(item_id)
                        }
                    }
                    else {
                        res.push(token)
                    }
                }

                let endpoint = res.join('/')
                let source = field.resource.source === null ? '' : field.resource.source
                let source_id = parseInt(params[source.split('-')[0].toLowerCase()])

                this.getList(endpoint, { _source: source + (!isNaN(source_id) ? '-' + source_id : '') }, success => {
                    callback(success)
                }, fail => {
                    callback([])
                }, false)
            }

            getOptions(params, parentItem, type, success = e => { }, fail = e => { }, headers = {}, register = false, only = false, feedback=true) {
                axios
                    .options(this.api_url + type + "/", headers)
                    .then(e => {
                        if (register) {
                            let ret = {}
                            let ready = Object.keys(e.data.form).length

                            Object.keys(e.data.form).forEach(k => {
                                this.getFields(e.data.form[k], params, parentItem, (fields) => {
                                    ready--
                                    ret = { [k]: fields, ...ret }

                                    if (ready === 0) {
                                        success(ret)
                                    }
                                })
                            })
                        }
                        else if (only) {
                            success({ fields: e.data.form, methods: e.data.methods, actions: e.data.actions, ordering: e.data.ordering })
                        }
                        else {
                            this.getFields(e.data.form, params, parentItem, (fields) => {
                                success({ fields: fields, methods: e.data.methods, actions: e.data.actions, ordering: e.data.ordering })
                            })
                        }
                    })
                    .catch(f => {
                        if (feedback){store.dispatch('snackbar/error', f.response.data.detail)}
                        fail(f)
                    })
            }

            getList(type, filters, success = e => {
            }, fail = e => {
            }, feedback = true) {
                this.toParamData(filters, (data) => {
                    axios
                        .get(this.api_url + type + "/?" + data)
                        .then(e => {
                            success(e.data)
                        })
                        .catch(f => {
                            if (feedback && f.response){store.dispatch('snackbar/error', f.response.data.detail)}
                            fail(f)
                        });
                })
            }

            getItem(type, id, success = e => {
            }, fail = e => {
            }, feedback = true) {
                axios
                    .get(this.api_url + type + "/" + id + "/")
                    .then(e => {
                        success(e.data);
                    })
                    .catch(f => {
                        if (feedback){store.dispatch('snackbar/error', f.response.data.detail)}
                        fail(f)
                    })
            }

            post(type, data, success = e => {
            }, fail = e => {
            }, headers = {}, feedback = true) {
                axios
                    .post(this.api_url + type + "/", data, headers)
                    .then(e => {
                        wsc.downloadOrCallback(e, success);
                    })
                    .catch(f => {
                        if (feedback){store.dispatch('snackbar/error', f.response.data.detail)}
                        fail(f)
                    })
            }

            putItem(type, id, data, success = e => {
            }, fail = e => {
            }, headers = {}, feedback = true) {
                axios
                    .put(this.api_url + type + "/" + id + "/", data, headers)
                    .then(e => {
                        success(e.data)
                    })
                    .catch(f => {
                        if (feedback){store.dispatch('snackbar/error', f.response.data.detail)}
                        fail(f)
                    })
            }

            patchItem(type, id, data, success = e => {
            }, fail = e => {
            }, headers = {}, feedback = true) {
                axios
                    .patch(this.api_url + type + "/" + (id ? id + "/" : ''), data, headers)
                    .then(e => {
                        success(e.data)
                    })
                    .catch(f => {
                        if (feedback && f.response){store.dispatch('snackbar/error', f.response.data.detail)}
                        fail(f)
                    })
            }

            createItem(type, data, success = e => {
            }, fail = e => {
            }, headers = {}, feedback = true) {
                axios
                    .post(this.api_url + type + "/", data, headers)
                    .then(e => {
                        wsc.downloadOrCallback(e, success)
                    })
                    .catch(f => {
                        if (feedback){store.dispatch('snackbar/error', f.response.data.detail)}
                        fail(f)
                    })
            }

            createBatch(type, data, success = e => {
            }, fail = e => {
            }, headers = {}, feedback = true) {
                axios
                    .post(this.api_url + type + "/", data, headers)
                    .then(e => {
                        wsc.downloadOrCallback(e, success)
                    })
                    .catch(f => {
                        if (feedback){store.dispatch('snackbar/error', f.response.data.detail)}
                        fail(f)
                    })
            }

            deleteItem(type, id, success = e => {
            }, fail = e => {
            }, feedback = true) {
                axios
                    .delete(this.api_url + type + "/" + id + "/")
                    .then(e => {
                        success(e.data)
                    })
                    .catch(f => {
                        if (feedback){store.dispatch('snackbar/error', f.response.data.detail)}
                        fail(f)
                    })
            }

            deleteBatch(type, ids, success = e => {
            }, fail = e => {
            }, feedback = true) {
                axios
                    .delete(this.api_url + type + "/", { data: ids })
                    .then(e => {
                        success(e.data)
                    })
                    .catch(f => {
                        if (feedback){store.dispatch('snackbar/error', f.response.data.detail)}
                        fail(f)
                    })
            }

            getRessourceUrl(url, success = e => {
            }, fail = e => {
            }) {
                axios
                    .get(url)
                    .then(e => {
                        success(e.data)
                    })
                    .catch(f => {
                        store.dispatch('snackbar/error', f.response.data.detail)
                        fail(f)
                    })
            }

            setAuthHeader(header) {
                axios.interceptors.request.use(
                    function (config) {
                        config.headers['Authorization'] = header
                        return config
                    },
                    function (error) {
                        return Promise.reject(error)
                    }
                )
            }
        }

        let wscs = new wsc();
        Vue.wsc = wscs;
        Vue.prototype.$wsc = wscs;
    }
};
