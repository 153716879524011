// initial state
const state = {
    drawer: true,
    drawer_mini: false,
    drawer_widgets: false,
    drawer_item: false,
    base_widgets: [],
    all_fields: {},
    multiple_items: []
};

// getters
const getters = {
    drawer: (state) => state.drawer,
    drawer_mini: (state) => state.drawer_mini,
    drawer_widgets: (state) => state.drawer_widgets,
    drawer_item: (state) => state.drawer_item,
    base_widgets: (state) => state.base_widgets,
    all_fields: (state) => state.all_fields,
    multiple_items: (state) => state.multiple_items
};

// mutations
const mutations = {
    CHANGE_DRAWER_VAL(state, val) {
        state.drawer = val;
    },
    CHANGE_DRAWER_MINI_VAL(state, val) {
        state.drawer_mini = val;
    },
    CHANGE_DRAWER_WIDGETS_VAL(state, val) {
        state.drawer_widgets = val;
    },
    CHANGE_BASE_WIDGETS_VAL(state, val) {
        state.base_widgets = val;
    },
    CHANGE_DRAWER_ITEM_VAL(state, val) {
        state.drawer_item = val;
    },
    CHANGE_ALL_FIELDS_VAL(state, val) {
        state.all_fields = val;
    },
    CHANGE_MULTIPLE_ITEMS_VAL(state, val) {
        state.multiple_items = val;
    }
};

// actions
const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
