import router from "@/router/index";
// initial state
const state = {
	sidebar_routes: [],
	window_scroll_y: 0,
	redirect: null,
	application_ready: false,
	dashboard_url: null,
	marketplace_url: null
};

// getters
const getters = {
	sidebar_routes: (state) => state.sidebar_routes,
	window_scroll_y: (state) => state.window_scroll_y,
	redirect: (state) => state.redirect,
	application_ready: (state) => state.application_ready,
	dashboard_url: (state) => state.dashboard_url,
	marketplace_url: (state) => state.marketplace_url
};

// mutations
const mutations = {
	CHANGE_SIDEBAR_ROUTES(state, val) {
		state.sidebar_routes = val
	},
	CHANGE_WINDOW_SCROLL_Y_VAL(state, val) {
		state.window_scroll_y = val
	},
	CHANGE_REDIRECT_VAL(state, val) {
		state.redirect = val
	},
	CHANGE_APPLICATION_READY_VAL(state, val) {
		state.application_ready = val
	},
	CHANGE_DASH_URL_VAL(state, val) {
		state.dashboard_url = val
	},
	CHANGE_MARKET_URL_VAL(state, val) {
		state.marketplace_url = val
	}
}

function callBack(that, callback) {
	if (that.getters['redirect'] !== null) {
		if (that.getters['auth/contact_id']) {
			router.push(that.getters['redirect']).then(callback)
		} else {
			if (that.getters['sidebar_routes'].children.find(e => e.name === 'marketplace')) {
				router.push('/marketplace').then(callback)
			} else {
				router.push('/auth').then(callback)
			}
		}
	} else {
		if (window.location.pathname.match(new RegExp(/^\/dashboard/))) {
			if (!that.getters['auth/contact_id']) {
				router.push('/auth').then(callback)
			} else {
				router.push(window.location.pathname + window.location.search).then(callback)
			}
		} else if (window.location.pathname.match(new RegExp(/^\/marketplace/))) {
			if (that.getters['sidebar_routes'].children.find(e => e.name === 'marketplace')) {
				router.push(window.location.pathname + window.location.search).then(callback)
			} else {
				router.push('/auth').then(callback)
			}
		} else {
			if (that.getters['auth/contact_id']) {
				router.push('/dashboard').then(callback)
			} else if (window.location.pathname === '/' && that.getters.dashboard_url !== null) {
				router.push('/auth').then(callback)
			} else if (window.location.pathname === '/' && that.getters.marketplace_url !== null) {
				router.push(that.getters.marketplace_url).then(callback)
			} else if (window.location.pathname === '/') {
				router.push('/auth').then(callback)
			} else {
				router.push(window.location.pathname + window.location.search).then(callback)
			}
		}
	}
}

async function createRouter(val, that, dash_url, market_url) {
	let data = val.data;
	let base_router = val.base_router;
	let parent = val.parent;
	let parent_name = val.parent_name;
	let dashboard = val.dashboard
	let marketplace = val.marketplace

	if (data.type === null) {
		if (data.children.length !== 0) {
			for (let i = 0; i < data.children.length; i++) {
				createRouter({
					base_router,
					data: data.children[i],
					parent: data.endpoint,
					parent_name: data.name,
					dashboard,
					marketplace
				}, that, dash_url, market_url)
			}
		}
	}
	else if (data.type === "menu") {
		if (data.children.length !== 0) {
			for (let i = 0; i < data.children.length; i++) {
				createRouter({
					base_router,
					data: data.children[i],
					parent: data.endpoint,
					parent_name: data.name,
					dashboard,
					marketplace
				}, that, dash_url, market_url)
			}
		}
	}
	else if (data.type === "dashboard") {
		let route_detail = {
			path: 'home',
			name: 'home',
			component: () => import('@/pages/dashboard/Home.vue')
		}

		base_router.push(dashboard)

		dashboard.children.push(route_detail)

		that.commit('CHANGE_DASH_URL_VAL', dash_url)

		return 'dashboard'
	}
	else if (data.type === 'marketplace') {
		base_router.push(marketplace)
		that.commit('CHANGE_MARKET_URL_VAL', market_url)

		return 'marketplace'
	}
	else {
		let route = {
			path: data.endpoint,
			name: data.name,
			component: () => import('@/pages/dashboard/Set.vue'),
			meta: {
				route_label: data.label
			},
			props: {
				icon: data.icon,
				routeType: data.type,
				routeLabel: data.label,
				routeChildren: data.children,
				routeParent: parent
			},
		};

		let route_detail = {
			path: data.endpoint + '/:' + data.name,
			name: data.name,
			component: () => import('@/pages/dashboard/Item.vue'),
			meta: {
				route_label: data.label
			},
			props: {
				routeType: data.type,
				routeComponents: data.components,
				routeLabel: data.label,
				routeIcon: data.icon,
				routePosition: data.position,
				routeParent: parent,
				routeParentName: parent_name,
				routeChildren: data.children
			}
		};

		dashboard.children[0].children.push(route)
		dashboard.children.push(route_detail)

		if (data.children.length !== 0) {
			for (let i = 0; i < data.children.length; i++) {
				createRouter({
					base_router,
					data: data.children[i],
					parent: data.endpoint,
					parent_name: data.name,
					dashboard,
					marketplace
				}, that, dash_url, market_url)
			}
		}
	}

	return 'autre'
}

// actions
const actions = {
	loadRoutes(context, lang) {
		const myHeaders = new Headers({
			"Accept-Language": lang,
			"Content-Type": "application/json",
		})

		const myInit = {headers: myHeaders,  credentials: "include"	}

		let error = {
			path: "/*",
			name: "error",
			component: () => import('@/pages/Error.vue'),
		}

		fetch(context.getters['config/backend_url'] + "resource/router/", myInit)
			.then((res) => res.json())
			.then((data) => {
				context.commit("CHANGE_SIDEBAR_ROUTES", data);

				let router_res = []

				let dashboard = {
					path: '/dashboard',
					name: 'home',
					redirect: '/dashboard/home',
					component: () => import('@/layout/dashboard/Dashboard.vue'),
					children: [
						{
							path: '',
							name: 'dashboard',
							redirect: '/dashboard/home',
							component: () => import('@/layout/dashboard/Root.vue'),
							children: []
						}
					]
				};

				let marketplace = {
					path: '/marketplace',
					name: 'marketplace',
					redirect: 'catalog',
					component: () => import('@/layout/marketplace/MarketPlace.vue'),
					children: [
						{
							path: '/',
							name: 'catalog',
							component: () => import('@/pages/marketplace/Catalog.vue'),
						},
						{
							path: 'product/:id',
							name: 'product',
							component: () => import('@/pages/marketplace/Product.vue'),
						},
						{
							path: 'cart',
							name: 'cart',
							component: () => import('@/pages/marketplace/Cart.vue'),
						},
						{
							path: 'checkout',
							name: 'checkout',
							component: () => import('@/pages/marketplace/Checkout.vue'),
						}
					]
				}

				createRouter({
					base_router: router_res,
					data,
					parent: "",
					parent_name: "",
					dashboard,
					marketplace
				}, this, dashboard.path, marketplace.path)
					.then(() => {
						router_res.push(error)
						router.replaceRoutes(router_res, () => {
							callBack(this, () => this.commit('CHANGE_APPLICATION_READY_VAL', true))
						})
					})
			})
			.catch(() => {
			})
	},

};

export default {
	state,
	getters,
	actions,
	mutations,
};
