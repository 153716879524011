import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#0072bc',
				secondary: '#26a69a',
				accent: colors.red.darken1,
				cell: colors.shades.white,
				cell2: colors.grey.lighten3,
				cell3: colors.grey.lighten2,
				cell4: colors.grey.base,
				cell5: colors.shades.white,
				celldark: colors.grey.darken2,
				background: colors.grey.lighten4,
				label: colors.grey.darken2,
				input: '#9e9e9e',
				inputHover: '#3e3e3e',
				black2white: colors.shades.black,
				sidebar: '#1c263c',
				labelprimary: '#0082d3'
			},
			dark: {
				primary: '#0082d3',
				secondary: '#26a69a',
				accent: colors.red.lighten1,
				cell: "#252525",
				cell2: '#353535',
				cell3: colors.grey.darken3,
				cell4: colors.grey.darken1,
				cell5: '#353535',
				celldark: colors.grey.darken2,
				background: "#111111",
				label: colors.grey.base,
				input: '#595959',
				inputHover: '#a5a5a5',
				black2white: colors.shades.white,
				sidebar: '#151925',
				labelprimary: '#4fbbff'
			},
		}
	},
});
